import * as React from "react";

interface Props {
  time: string;
  person?: string;
  position?: string;
  children: string | React.ReactNode;
}

export const AgendaItem = (props: Props) => {
  const { children, person, position, time } = props;
  return (
    <div>
      <b>{time}</b>
      <br />
      {children}
      {person && (
        <div className="text-sm text-gray-500 mt-0.5">
          {person} {position && ` (${position})`}
        </div>
      )}
    </div>
  );
};

export const AgendaItemBreak = ({ time }: { time: string }) => (
  <AgendaItem time={time}>Przerwa kawowa / Networking</AgendaItem>
);
