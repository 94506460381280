import * as React from "react";

interface Props {
  title: string;
  date: string;
  hours: string;
  place?: "Tychy" | "Katowice";
  council?: string[];
}

export const AgendaSection = (props: React.PropsWithChildren<Props>) => {
  const { title, date, hours, place, children, council } = props;
  return (
    <div className="mt-6 py-12">
      <h4 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-8">
        {title}
      </h4>

      <div className="lg:grid lg:grid-cols-3 lg:col-gap-8">
        <div className="lg:col-span-1">
          <h3 className="font-semibold tracking-tight text-gray-900">Data:</h3>
          <p>{date}</p>
          <h3 className="font-semibold tracking-tight text-gray-900 mt-6">
            Czas trwania:
          </h3>
          <p>{hours}</p>
          {place && (
            <h3 className="font-semibold tracking-tight text-gray-900 mt-6">
              Miejsce:
            </h3>
          )}
          {place === "Tychy" && <p>Tychy, aula ul. Barona 30</p>}
          {place === "Katowice" && (
            <p>
              Katowice
              <br />
              Politechnika Śląska <br />
              Aula Centrum Badawczo-Edukacyjnego
              <br />
              ul. Krasińskiego 8
            </p>
          )}
          {council && (
            <>
              <h3 className="font-semibold tracking-tight text-gray-900 mt-6">
                Rada programowa:
              </h3>
              <ul className="list-disc list-inside">
                {council.map((person) => (
                  <li key={person}>{person}</li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="mt-10 lg:col-span-2 lg:mt-0">
          <div className="leading-relaxed space-y-6">{children}</div>
        </div>
      </div>
    </div>
  );
};
