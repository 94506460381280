import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Agenda } from "../components/Agenda";
import { Background } from "../components/Background";
import { Features } from "../components/Features";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";

const IndexPage = () => {
  return (
    <>
      <Helmet
        title="Academy Smart Production"
        description="Academy Smart Production to forum wymiany wiedzy z zakresu zarządzania i inżynierii produkcji."
      >
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <link rel="icon" type="image/png" href="/icon.png" />
      </Helmet>

      <title>Academy Smart Production</title>

      <Background />

      <main className="isolate mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
        <Hero />
        <Agenda />
        <Features />
        {/* <Join /> */}
        <section className="mt-12 md:mt-16 lg:mt-20">
          <h3 className="font-medium mb-4 text-sm text-gray-500">
            Partnerzy ASP:
          </h3>
          <div className="flex space-x-1 md:space-x-2.5 items-center">
            <a href="https://leancenter.pl" target="_blank">
              <StaticImage
                src="../images/leancenter.jpg"
                alt="Lean Center"
                width={137}
                height={90}
                placeholder="none"
              />
            </a>
            <a href="http://lean-technology.pl" target="_blank">
              <StaticImage
                src="../images/leantechnology.jpg"
                alt="Lean Technology"
                width={90}
                height={90}
                placeholder="none"
              />
            </a>
            <StaticImage
              src="../images/dapr.png"
              alt="Data Acquisition Processing Reports"
              width={320}
              height={88}
              placeholder="none"
            />
            <a href="https://rubylogic.eu/pl" target="_blank">
              <StaticImage
                src="../images/ruby.jpg"
                alt="Ruby Logic"
                width={90}
                height={90}
                placeholder="none"
              />
            </a>
            <a href="https://jakoscbezretuszu.pl" target="_blank">
              <StaticImage
                src="../images/jakosc-farion.png"
                alt="Jakość bez retuszu"
                width={154}
                height={90}
                placeholder="none"
              />
            </a>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default IndexPage;
