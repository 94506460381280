import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Hero = () => {
  return (
    <div className="mt-16 sm:mt-24 lg:mt-32">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center  md:mx-auto lg:col-span-7 lg:text-left">
          <div className="flex gap-8 items-center">
            <StaticImage
              src="../images/logo-asp.png"
              alt="Academy Smart Production"
              width={241}
              height={50}
              placeholder="none"
            />
            <StaticImage
              src="../images/logo-ps.png"
              alt="Politechnika Śląska"
              width={186}
              height={50}
              placeholder="none"
            />
          </div>
          <h1 className="mt-12">
            <span className="block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
              <span className="block text-gray-900">Platforma wymiany</span>
              <span className="block md:mt-1.5 text-lead-500">
                wiedzy i doświadczeń
              </span>
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            Academy Smart Production to forum wymiany wiedzy z zakresu
            zarządzania i inżynierii produkcji. Grupa, działająca przy
            Politechnice Śląskiej, spotyka się w ramach warsztatów, wymieniając
            się doświadczeniem pomocnym na drodze ciągłego doskonalenia
            organizacji i przedsiębiorstw.
          </p>
        </div>
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-5 lg:flex lg:items-center">
          <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
            <StaticImage
              className="block w-full rounded-lg overflow-hidden"
              src="../images/spotkanieasp.jpg"
              alt="Spotkanie"
              width={1024}
              height={580}
              transformOptions={{
                fit: "cover",
                cropFocus: "center",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
