import * as React from "react";

export const Background = () => {
  return (
    <div className="fixed inset-0">
      <img
        src="/bg.jpg"
        alt=" "
        className="w-full h-full object-cover opacity-30"
      />
    </div>
  );
};
